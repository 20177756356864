import React, { useState } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import './Account.css';

function Account() {
  const [dateRange, setDateRange] = useState('7d');
  const [searchTerm, setSearchTerm] = useState('');

  // Mock data for USD balance - without currency symbol
  const balance = {
    currency: 'USD',
    amount: 1234567.89, // Store as number
    icon: <FaDollarSign />
  };

  // Simplified mock transactions array
  const transactions = [
    {
      id: 1,
      date: '2024-03-15',
      type: 'Deposit',
      description: 'Wire Transfer from Bank of America',
      amount: '500,000.00',
      balance: '1,234,567.89',
      status: 'Completed'
    },
    {
      id: 2,
      date: '2024-03-14',
      type: 'Withdrawal',
      description: 'Wire Transfer to JPMorgan Chase',
      amount: '250,000.00',
      balance: '734,567.89',
      status: 'Completed'
    },
    {
      id: 3,
      date: '2024-03-12',
      type: 'Deposit',
      description: 'ACH Transfer from Wells Fargo',
      amount: '100,000.00',
      balance: '984,567.89',
      status: 'Completed'
    },
    {
      id: 4,
      date: '2024-03-10',
      type: 'Withdrawal',
      description: 'Wire Transfer to Citibank',
      amount: '75,000.00',
      balance: '884,567.89',
      status: 'Pending'
    }
  ];

  // Format number as currency
  const formatCurrency = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  // Filter transactions based on date range and search term
  const getFilteredTransactions = () => {
    let filtered = [...transactions];
    
    // Date filtering
    if (dateRange !== 'all') {
      const today = new Date();
      const filterDate = new Date();
      
      switch(dateRange) {
        case '7d':
          filterDate.setDate(today.getDate() - 7);
          break;
        case '30d':
          filterDate.setDate(today.getDate() - 30);
          break;
        case '90d':
          filterDate.setDate(today.getDate() - 90);
          break;
        default:
          break;
      }

      filtered = filtered.filter(tx => {
        const txDate = new Date(tx.date);
        return txDate >= filterDate;
      });
    }

    // Search filtering
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(tx =>
        tx.description.toLowerCase().includes(searchLower) ||
        tx.amount.includes(searchTerm) ||
        tx.type.toLowerCase().includes(searchLower)
      );
    }

    return filtered;
  };

  return (
    <div className="account-container">
      <div className="account-header">
        <h2>Account Balance </h2>
        <div className="balance-display">       
          <span className="balance-amount">${formatCurrency(balance.amount)}</span>
        </div>
      </div>

      <div className="transactions-section">
        <div className="transactions-header">
          <h3>Transaction History</h3>
          <div className="filters">
            <select 
              value={dateRange} 
              onChange={(e) => setDateRange(e.target.value)}
              className="date-filter"
            >
              <option value="7d">Last 7 days</option>
              <option value="30d">Last 30 days</option>
              <option value="90d">Last 90 days</option>
              <option value="all">All time</option>
            </select>
            <input
              type="text"
              placeholder="Search transactions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
        </div>

        <div className="transactions-table-container">
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Balance</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {getFilteredTransactions().map((tx) => (
                <tr key={tx.id}>
                  <td>{new Date(tx.date).toLocaleDateString()}</td>
                  <td>{tx.description}</td>
                  <td>
                    <span className={`transaction-type ${tx.type.toLowerCase()}`}>
                      {tx.type}
                    </span>
                  </td>
                  <td className={`amount ${tx.type.toLowerCase()}`}>
                    {tx.type === 'Withdrawal' ? '-' : '+'}${tx.amount}
                  </td>
                  <td>${tx.balance}</td>
                  <td>
                    <span className={`status ${tx.status.toLowerCase()}`}>
                      {tx.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Account;