import React, { useState } from 'react';
import { FaUser, FaBuilding } from 'react-icons/fa';
import './GetStartedPopup.css'; // Make sure this CSS file exists and is imported

function GetStartedPopup({ onClose }) {
  const [step, setStep] = useState(1);

  return (
    <div className="popup-overlay">
      <div className="popup-content get-started-popup">
        <button className="close-btn" onClick={onClose}>&times;</button>
        <h2 className="popup-title">Choose Your Account Type</h2>
        <div className="account-types">
          <div className="account-type">
            <FaUser size={48} />
            <h3>Individual</h3>
            <p>For personal trading and investments</p>
            <button className="apply-btn">Apply Now</button>
          </div>
          <div className="account-type">
            <FaBuilding size={48} />
            <h3>Institutional</h3>
            <p>For businesses and institutions</p>
            <button className="apply-btn">Apply Now</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStartedPopup;
