import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="App-header">
      <nav>
        <div className="logo">
          <Link to="/"><span><strong>D1</strong>technologies</span></Link>
        </div>
        <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <ul>
            <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li><Link to="/#about" onClick={toggleMenu}>About Us</Link></li>
            <li><Link to="/#services" onClick={toggleMenu}>Our Services</Link></li>
            <li><Link to="/login" className="login-button" onClick={toggleMenu}>Login</Link></li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
    </header>
  );
}

export default Header;
