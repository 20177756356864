import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStytch } from '@stytch/react';
import './PasswordReset.css';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const stytch = useStytch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await stytch.passwords.resetByEmailStart({
        email: email,
        reset_password_redirect_url: `${window.location.origin}/reset-password`,
        reset_password_expiration_minutes: 30,
        login_redirect_url: `${window.location.origin}/login`
      });

      setMessage('If an account exists with this email, you will receive a password reset link');
      setError('');
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      console.error('Reset error:', err);
      setError(err.message || 'Failed to send reset link');
      setMessage('');
    }
  };

  return (
    <div className="password-reset-container">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        {message && <div className="success-message">{message}</div>}
        {error && <div className="error-message">{error}</div>}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </div>
        <button type="submit" className="reset-button">
          Send Reset Link
        </button>
        <button 
          type="button" 
          className="back-button"
          onClick={() => navigate('/login')}
        >
          Back to Login
        </button>
      </form>
    </div>
  );
}

export default PasswordReset; 