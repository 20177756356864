import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStytch } from '@stytch/react';

function ProtectedRoute({ children }) {
  const stytch = useStytch();
  const isAuthenticated = stytch.session.getSync() !== null;
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (!isAuthenticated && !isDevelopment) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default ProtectedRoute; 