import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { StytchProvider } from '@stytch/react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import OurPartners from './components/OurPartners';
import PartnerLogos from './components/PartnerLogos';
import Footer from './components/Footer';
import Login from './components/Login';
import PasswordReset from './components/PasswordReset';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';
import { StytchConfig } from './utils/stytch';
import Dashboard from './components/dashboard/Dashboard';
import DashboardHeader from './components/dashboard/DashboardHeader';
import ProtectedRoute from './components/auth/ProtectedRoute';

function ScrollToHashElement() {
  const location = useLocation();

  React.useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
}

function AppContent() {
  const location = useLocation();
  const isDashboard = location.pathname.startsWith('/dashboard');

  if (isDashboard) {
    return (
      <div className="App">
        <ProtectedRoute>
          <DashboardHeader />
          <Dashboard />
        </ProtectedRoute>
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <main>
        <ScrollToHashElement />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <AboutUs />
              <Services />
              <OurPartners />
              <PartnerLogos />
            </>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<PasswordReset />} />
          <Route path="/reset-password" element={<ResetPasswordConfirm />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <StytchConfig>
      <Router>
        <Routes>
          <Route path="/dashboard/*" element={<AppContent />} />
          <Route path="/dashboard" element={<Navigate to="/dashboard/account" replace />} />
          <Route path="*" element={<AppContent />} />
        </Routes>
      </Router>
    </StytchConfig>
  );
}

export default App;
