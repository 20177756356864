import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';

const stytchClient = new StytchUIClient(
  process.env.REACT_APP_STYTCH_PUBLIC_TOKEN,
  {
    project_id: process.env.REACT_APP_STYTCH_PROJECT_ID,
    env: process.env.REACT_APP_STYTCH_ENV
  }
);

export function StytchConfig({ children }) {
  return (
    <StytchProvider stytch={stytchClient}>
      {children}
    </StytchProvider>
  );
}
