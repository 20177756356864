import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import Account from './Account';
import TradingDesk from './TradingDesk';
import './Dashboard.css';

// Placeholder component for Crypto
const Crypto = () => <div>Crypto Dashboard</div>;

function Dashboard() {
  return (
    <div className="dashboard-layout">
      <div className="dashboard-container">
        <nav className="dashboard-sidebar">
          <div className="sidebar-links">
            <NavLink 
              to="/dashboard/account" 
              className={({ isActive }) => 
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
            >
              Account
            </NavLink>
            <NavLink 
              to="/dashboard/trading-desk" 
              className={({ isActive }) => 
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
            >
              Trading Desk
            </NavLink>
            <NavLink 
              to="/dashboard/crypto" 
              className={({ isActive }) => 
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
            >
              Crypto
            </NavLink>
          </div>
        </nav>
        
        <main className="dashboard-content">
          <Routes>
            <Route path="account" element={<Account />} />
            <Route path="trading-desk" element={<TradingDesk />} />
            <Route path="crypto" element={<Crypto />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;