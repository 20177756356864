import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStytch } from '@stytch/react';
import { FaChevronDown, FaUser, FaSignOutAlt } from 'react-icons/fa';
import './DashboardHeader.css';

function DashboardHeader() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const stytch = useStytch();

  const handleLogout = async () => {
    try {
      await stytch.session.revoke();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <header className="dashboard-header">
      <nav>
        <div className="logo">
          <Link to="/dashboard">
            <span><strong>D1</strong>technologies</span>
          </Link>
        </div>
        
        <div className="user-menu">
          <button 
            className="user-dropdown-button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            John Doe
            <FaChevronDown className={`dropdown-icon ${isDropdownOpen ? 'open' : ''}`} />
          </button>
          
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <Link to="/dashboard/profile" className="dropdown-item">
                <FaUser /> Profile
              </Link>
              <button onClick={handleLogout} className="dropdown-item">
                <FaSignOutAlt /> Logout
              </button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export default DashboardHeader; 