import React, { useState } from 'react';
import { 
  FaBitcoin, 
  FaDollarSign 
} from 'react-icons/fa';
import { 
  SiEthereum, 
  SiTether 
} from 'react-icons/si';
import './TradingDesk.css';

function TradingDesk() {
  const [tradeType, setTradeType] = useState('buy');
  const [amount, setAmount] = useState('');
  const [fromCurrency, setFromCurrency] = useState('BTC');
  const [toCurrency, setToCurrency] = useState('USD');

  const cryptoOptions = [
    { symbol: 'BTC', name: 'Bitcoin', icon: <FaBitcoin /> },
    { symbol: 'ETH', name: 'Ethereum', icon: <SiEthereum /> },
    { symbol: 'USDT', name: 'Tether', icon: <SiTether /> },
    { symbol: 'USDC', name: 'USD Coin', icon: <FaDollarSign /> }
  ];

  const fiatOptions = [
    { symbol: 'USD', name: 'US Dollar', icon: <FaDollarSign /> },
    { symbol: 'USDT', name: 'Tether', icon: <SiTether /> },
    { symbol: 'USDC', name: 'USD Coin', icon: <FaDollarSign /> }
  ];

  const handleCurrencyChange = (value, type) => {
    if (type === 'from') {
      setFromCurrency(value);
      if (value === toCurrency) {
        setToCurrency(fiatOptions.find(curr => curr.symbol !== value)?.symbol || 'USD');
      }
    } else {
      setToCurrency(value);
      if (value === fromCurrency) {
        setFromCurrency(cryptoOptions.find(curr => curr.symbol !== value)?.symbol || 'BTC');
      }
    }
  };

  const handleGetQuote = () => {
    console.log('Getting quote for:', {
      type: tradeType,
      amount,
      fromCurrency,
      toCurrency
    });
  };

  return (
    <div className="trading-desk-container">
      <div className="quote-box">
        <h2>Obtain Quote</h2>
        <p className="subtitle">Enter your trade request details below</p>

        <div className="trade-type-selector">
          <button
            className={`trade-button ${tradeType === 'buy' ? 'active' : ''}`}
            onClick={() => setTradeType('buy')}
          >
            Buy
          </button>
          <button
            className={`trade-button ${tradeType === 'sell' ? 'active' : ''}`}
            onClick={() => setTradeType('sell')}
          >
            Sell
          </button>
        </div>

        <div className="trade-inputs">
          <div className="input-group">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
              className="amount-input"
            />
            <select
              value={fromCurrency}
              onChange={(e) => handleCurrencyChange(e.target.value, 'from')}
              className="currency-select"
            >
              {cryptoOptions.map(currency => (
                <option 
                  key={currency.symbol} 
                  value={currency.symbol}
                  disabled={currency.symbol === toCurrency}
                >
                  {currency.symbol}
                </option>
              ))}
            </select>
            <div className="selected-currency-icon">
              {cryptoOptions.find(c => c.symbol === fromCurrency)?.icon}
            </div>
          </div>

          <div className="for-label">FOR</div>

          <div className="input-group">
            <select
              value={toCurrency}
              onChange={(e) => handleCurrencyChange(e.target.value, 'to')}
              className="currency-select"
            >
              {fiatOptions.map(currency => (
                <option 
                  key={currency.symbol} 
                  value={currency.symbol}
                  disabled={currency.symbol === fromCurrency}
                >
                  {currency.symbol}
                </option>
              ))}
            </select>
            <div className="selected-currency-icon">
              {fiatOptions.find(c => c.symbol === toCurrency)?.icon}
            </div>
          </div>
        </div>

        <button 
          className="quote-button"
          onClick={handleGetQuote}
          disabled={!amount || !fromCurrency || !toCurrency}
        >
          Get Quote
        </button>
      </div>
    </div>
  );
}

export default TradingDesk; 