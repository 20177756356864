import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStytch } from '@stytch/react';
import './PasswordReset.css';

function ResetPasswordConfirm() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [requirements, setRequirements] = useState({
    hasMinLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
    passwordsMatch: false
  });
  const [strength, setStrength] = useState(0);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const stytch = useStytch();

  useEffect(() => {
    // Calculate password strength
    const strengthScore = Object.values(requirements).filter(Boolean).length;
    setStrength((strengthScore / 6) * 100);
  }, [requirements]);

  const checkPasswordStrength = (password) => {
    setRequirements({
      hasMinLength: password.length >= 8,
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      passwordsMatch: password === confirmPassword
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setRequirements(prev => ({
      ...prev,
      passwordsMatch: password === newConfirmPassword
    }));
  };

  const getStrengthColor = () => {
    if (strength < 50) return '#dc3545';
    if (strength < 80) return '#ffc107';
    return '#28a745';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!Object.values(requirements).every(Boolean)) {
      setError('Please meet all password requirements');
      return;
    }

    setIsLoading(true);
    try {
      const token = new URLSearchParams(location.search).get('token');
      
      await stytch.passwords.resetByEmail({
        token: token,
        password: password,
        session_duration_minutes: 60
      });
      
      setMessage('Password reset successful!');
      setTimeout(() => {
        navigate('/login', { 
          state: { message: 'Password reset successful. Please log in with your new password.' }
        });
      }, 2000);
    } catch (error) {
      setError(error.message || 'An error occurred during password reset');
    } finally {
      setIsLoading(false);
    }
  };

  const RequirementIcon = ({ met }) => (
    <span className={`requirement-icon ${met ? 'met' : ''}`}>
      {met ? '✓' : '○'}
    </span>
  );

  return (
    <div className="password-reset-container">
      <h2>Set New Password</h2>
      {message && <div className="success-message animate-fade-in">{message}</div>}
      {error && <div className="error-message animate-shake">{error}</div>}
      
      <form onSubmit={handleSubmit} className="password-form">
        <div className="form-group">
          <label>New Password</label>
          <div className="password-input-wrapper">
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="password-input"
              placeholder="Enter your new password"
            />
            <div className="password-strength-indicator">
              <div 
                className="strength-bar" 
                style={{ 
                  width: `${strength}%`,
                  backgroundColor: getStrengthColor(),
                  transition: 'all 0.3s ease'
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className="password-input"
            placeholder="Confirm your password"
          />
        </div>

        <div className="password-requirements animate-fade-in">
          <h3>Password Requirements</h3>
          <div className="requirements-grid">
            {Object.entries(requirements).map(([key, met]) => (
              <div 
                key={key} 
                className={`requirement-item ${met ? 'met' : ''}`}
              >
                <RequirementIcon met={met} />
                <span>
                  {key === 'hasMinLength' && '8+ Characters'}
                  {key === 'hasUppercase' && 'Uppercase'}
                  {key === 'hasLowercase' && 'Lowercase'}
                  {key === 'hasNumber' && 'Number'}
                  {key === 'hasSpecialChar' && 'Special Char'}
                  {key === 'passwordsMatch' && 'Matching'}
                </span>
              </div>
            ))}
          </div>
        </div>

        <button 
          type="submit" 
          className={`reset-button ${isLoading ? 'loading' : ''}`}
          disabled={!Object.values(requirements).every(Boolean) || isLoading}
        >
          {isLoading ? (
            <span className="button-content">
              <span className="spinner"></span>
              Resetting...
            </span>
          ) : (
            'Reset Password'
          )}
        </button>
      </form>
    </div>
  );
}

export default ResetPasswordConfirm;